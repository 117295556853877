import axios from 'axios'

export class Telefone {

    static async addTelefone (servidor_id,aluno_id, telefone, identificador) {
        return axios.post('/telefone/login/'+servidor_id+'/'+aluno_id,{
            telefone: telefone,
            identificador: identificador
          });
    }

    static async editarTelefone (payload) {
        return axios.put('/telefone/'+payload.id,{
            telefone: payload.telefone,
            identificador: payload.identificador
        });
    }

    static async removerTelefone (id) {
        return axios.delete('/telefone/'+ id);
    }

    static async obtemTelefone (telefone) {
        return axios.get('telefone/buscar?telefone='+telefone);
      }
}
