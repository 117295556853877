<template>
    <div  class="p-steps p-component p-readonly" >
        <ul role="tablist" >
          <div v-for="n in items" :key="n.label" style="width: 100%;">
            <li 
             class="p-steps-item p-highlight p-steps-current" role="tab" aria-selected="true" aria-expanded="true" v-if="n.active">
              <a href="#" class="p-menuitem-link router-link-active router-link-active-exact" role="presentation">
                  <span class="p-steps-number">{{n.number}}</span>
                  <span class="p-steps-title">{{n.label}}</span>
              </a>
            </li>
            <li class="p-steps-item p-disabled" role="tab" aria-selected="false" aria-expanded="false" v-else>
              <span class="p-menuitem-link" role="presentation">
                <span class="p-steps-number">{{n.number}}</span>
                <span class="p-steps-title">{{n.label}}</span>
                </span>
            </li>
          </div>
        </ul>
    </div>
</template>
<script>

export default {
    props:{
      items:{}
    },
    data () {
        return {
           
        }
    },
    methods: {

    }
}
</script>
